<template lang="pug">
Dropdown#size(
  :label="labelOverride || $t('align')"
  :layout="layout"
  :items="size"
  v-model="sizeValue"
  :editMobile="editMobile"
  :deviceSelector="deviceSelector"
)
</template>
<script>
  import { mapGetters, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import Dropdown from '../Dropdown/Dropdown.vue';

  export default {
    components: {
      Dropdown,
    },
    mixins: [itemMixin],
    props: {
      value: {
        type: Object,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      deviceSelector: {
        type: Boolean,
        default: false,
      },
      labelOverride: {
        type: String,
      },
      layout: {
        type: String,
        default: 'row',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
      typeOfComponent: {
        type: String,
      },
    },
    computed: {
      ...mapState(['mobilePreview']),
      ...mapGetters(['isNano']),
      isButtonOrCoupon() {
        const isButton = this.typeOfComponent === 'button';
        const isCoupon = this.typeOfComponent === 'coupon';

        return isButton || isCoupon;
      },
      sizeValue: {
        get() {
          return this.getValueOf(`smartSize.type`);
        },
        set(v) {
          this.setValueOf(`smartSize.type`, v);
        },
      },
      size() {
        const array = [];

        if (this.isButtonOrCoupon) {
          array.push({ value: 'fluid', text: this.$t('fluid') });
        }
        if (this.typeOfComponent === 'survey') {
          array.push({ value: 'fluid', text: this.$t('fitToContent') });
        }
        if (this.mobilePreview && this.editMobile) {
          array.push({ value: null, text: this.$t('default') });
        }

        array.push({ value: 'manual', text: this.$t('manual') });

        if (!this.isNano) {
          const keyText = this.typeOfComponent === 'button' ? 'simple100' : 'w100';
          array.push({ value: '100w', text: this.$t(keyText) });
        }
        return array;
      },
    },
  };
</script>
