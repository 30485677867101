<template lang="pug">
div
  component(
    v-if="coupon.type !== 'gamification'"
    :is="coupon.type"
    @shopifyFixedCoupon="shopifyFixedCouponHandler"
    @loadingFixedCoupon="loadingFixedCouponHandler"
  )
  Heading.mt-4(expandableGap="8px" fix v-if="showAutoRedeem")
    template(#titleTrailing)
      .font-weight-bold {{ $t('autoRedeem') }}
    template(#prependEnding)
      OmSwitch#autoRedeem(v-model="autoRedeem")
    template(#help)
      span {{ $t('autoRedeemTooltip') }}
  OmInput#url(:label="$t('couponCopy.title')" small v-model="couponCopy")
    template(#helpText)
      span {{ $t('couponCopy.helper') }}
</template>
<script>
  import itemMixin from '@/editor/mixins/item';
  import Heading from '@/components/Editor/Heading.vue';
  import sharedMixin from '@/components/Editor/Blocks/Coupon/shared';

  export default {
    components: {
      Heading,
      fixed: () => import('./Fixed.vue'),
      unique: () => import('./Unique.vue'),
      shopify_automatic: () => import('./ShopifyAutomatic.vue'),
      followup: () => import('./Followup.vue'),
    },
    mixins: [itemMixin, sharedMixin],
    data() {
      return {
        displayAutoRedeem: false,
        shopifyFixedCoupon: false,
        loadingFixedCoupon: false,
      };
    },
    computed: {
      autoRedeem: {
        get() {
          return this.getValueOf('data.coupon.autoRedeem');
        },
        set(v) {
          this.setValueOf('data.coupon.autoRedeem', v);
        },
      },
      couponCopy: {
        get() {
          return this.getValueOf('data.coupon.customCopyText');
        },
        set(v) {
          this.setValueOf('data.coupon.customCopyText', v);
        },
      },
    },
    methods: {
      shopifyFixedCouponHandler(v) {
        this.shopifyFixedCoupon = v;
      },
      loadingFixedCouponHandler(v) {
        this.loadingFixedCoupon = v;
      },
    },
  };
</script>
<style lang="sass">
  .sub-title
    color: #8F97A4
</style>
